.app__navbar {
  width: 100vw;
  height: var(--navbar-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  background-color: var(--color-light-blue);
  padding: 1vw 2vw;
  position: sticky;
  top: 0px;
  z-index: 100;
}

.app__navbar_row0 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.app__navbar_row1 {
  width: 100%;
  display: none;
  padding: 0;
  margin: 0;
}

.app__navbar-logo {
  padding-left: 2.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__navbar-logo h1 {
  font-size: 2.6vw;
  font-weight: 500;
  font-family: Helvetica, Arial, sans-serif;
  color: white;
  margin: 0px;
  cursor: pointer;
}

.app_navbar-avatar {
  display: none;
  margin-bottom: 4px;
}

.app__navbar_menu_spreaded {
  flex: 1;
}

.app__navbar_menu_button {
  display: none;
  flex: 1;
  justify-content: flex-end;
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex;
  list-style: none;
}

.app__navbar-links li {
  margin: 0 1rem;
  cursor: pointer;
}

.app__navbar-links li:hover {
  color: var(--color-grey);
}

.app__navbar a {
  color: unset;
  text-decoration: none;
}

.app__navbar-links-expanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: hidden;
  max-height: 250px;
  transition: max-height 0.5s ease-in-out;
}

.app__navbar-menu-collapsed {
  max-height: 0;
}

.navbar_menu_title {
  font-size: 1.6vw;
  font-family: var(--font-base);
  font-weight: 300;
  color: white;
}

@media screen and (min-width: 2000px) {
  .app__navbar {
    padding: 20px 40px;
    height: 104px;
  }

  .app__navbar-logo {
    padding-left: 46px;
  }

  .app__navbar-logo h1 {
    font-size: 52px;
  }

  .navbar_menu_title {
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  .app__navbar {
    padding: 0.5rem;
    height: unset;
  }

  .app__navbar_row0 {
    justify-content: center;
  }

  .app__navbar-logo {
    padding-left: 10px;
  }

  .app__navbar-logo h1 {
    font-size: 30px;
  }

  .app__navbar_menu_spreaded {
    display: none;
  }

  .app_navbar-avatar {
    display: block;
  }

  .app__navbar_menu_button {
    display: flex;
  }

  .app__navbar_row1 {
    display: block;
  }

  .navbar_menu_title {
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .navbar_menu_title a {
    display: block;
    width: 100%;
    text-align: left;
  }
}
