.app_header {
  background-color: black;
  position: relative;
  width: 100vw;
  height: calc(100vh - var(--navbar-height));
  min-height: calc(560px - var(--navbar-height));
  scroll-margin-top: var(--navbar-height);
}

.carousel_img_container {
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  min-height: calc(560px - var(--navbar-height));
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel_img {
  width: 100%;
  object-fit: cover;
  opacity: 0.7;
  height: 100%;
}

.carousel_text_bg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  left: calc(15% + 25vw);
  padding: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.carousel-title {
  font-size: 3.1vw;
  font-weight: 500;
  color: #fff;
  padding: 0;
  margin: 0 0 16px 0;
}

.carousel_text {
  color: #fff;
  font-size: 2.2vw;
  text-align: left;
  font-weight: 300;
  padding: 0;
  margin: 0 0 16px 0;
}

.search_paper_container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media screen and (min-width: 1536px) {
}

@media screen and (max-width: 1536px) {
}

@media screen and (max-width: 1358px) {
  .carousel-title {
    font-size: 36px;
  }

  .carousel_text {
    font-size: 28px;
  }
}

@media screen and (max-width: 1024px) {
  .carousel-title {
    font-size: 32px;
  }

  .carousel_text {
    font-size: 24px;
  }
}

@media screen and (max-width: 960px) {
  .carousel-title {
    font-size: 28px;
  }

  .carousel_text {
    font-size: 20px;
  }
}

@media screen and (max-width: 820px) {
  .carousel-title {
    font-size: 24px;
  }

  .carousel_text {
    font-size: 16px;
  }
} */

@media screen and (max-width: 768px) {
  .app_header {
    height: unset;
    scroll-margin-top: 300px;
  }

  .carousel_text_bg {
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 5%;
  }

  .carousel-title {
    font-size: 20px;
  }

  .carousel_text {
    font-size: 16px;
  }

  .carousel_img_container {
    height: 100%;
  }

  .carousel_img {
    width: unset;
    height: 100%;
  }

  .search_paper_container {
    position: unset;
    background-color: whitesmoke;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}
