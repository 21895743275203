.app__footer {
  width: 100vw;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-light-blue);
  padding: 1rem 1rem;
  z-index: 10;
}

.app__footer-row0 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__footer-row1 {
  width: 100%;
  padding-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer_logo {
  width: 210px;
  cursor: pointer;
}

.footer-link {
  color: #d2dbe4;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (min-width: 2000px) {
}

@media screen and (max-width: 1150px) {
}

@media screen and (max-width: 1095px) {
  .footer-card-brands {
    width: 40%;
  }

  .footer-link {
    font-size: 10px;
  }
}

@media screen and (max-width: 800px) {
  .app__footer {
    padding: 0.5rem 0.5rem;
  }

  .footer_logo {
    width: 160px;
  }
}
