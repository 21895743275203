.app_aboutus {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  min-height: 560px;
  padding: 4vw 6vw;
}

.app_aboutus-img {
  flex: 1;
  height: 100vh;
  min-height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app_aboutus-img img {
  object-fit: contain;
}

.app_aboutus-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  margin-top: 3%;
}

.app_aboutus-title {
  font-size: max(3vw, 6vh);
  font-family: var(--font-base);
  font-weight: 300;
  color: var(--color-golden);
  margin: 0;
  margin-bottom: 20px;
  align-self: flex-start;
}

.app_aboutus-text {
  color: black;
  font-size: max(1vw, 2vh);
  font-family: var(--font-base);
  margin: 0;
}

@media screen and (min-width: 1536px) {
}

@media screen and (max-width: 1536px) {
}

@media screen and (max-width: 1358px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 960px) {
}

@media screen and (max-width: 820px) {
}

@media screen and (max-width: 768px) {
  .app_aboutus {
    height: unset;
    min-height: unset;
    flex-direction: column;
    padding: 2rem 2rem;
    scroll-margin-top: 180px;
  }

  .app_aboutus-img {
    flex: unset;
    height: unset;
    min-height: unset;
  }

  .app_aboutus-img img {
    width: 80%;
  }

  .app_aboutus-content {
    flex: unset;
    margin-top: 0px;
  }

  .app_aboutus-title {
    font-size: 32px;
    font-weight: 400;
  }

  .app_aboutus-text {
    font-size: 16px;
  }
}
