@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
  --navbar-height: 5.2vw;
  --color-golden: #dcca87;
  --color-grey: #aaaaaa;
  --color-light-blue: #1976d2;

  --font-base: 'Open Sans', sans-serif;
  --font-alt: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px; /* for vertical scrollbars */
  height: 8px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgb(210, 210, 210);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 2000px) {
  :root {
    --navbar-height: 104px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --navbar-height: unset;
  }
}
