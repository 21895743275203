.app_howtouse {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  min-height: 560px;
  padding: 4vw 6vw;
}

.app_howtouse-overlay {
  position: absolute;
  inset: 0;
}

.app_howtouse-overlay img {
  width: 100%;
  height: 100vh;
  min-height: 560px;
  z-index: 0;
  object-fit: cover;
}

.app_howtouse-content {
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app_howtouse-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.app_howtouse-content_section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.app_howtouse_title {
  font-size: max(3vw, 6vh);
  font-family: var(--font-base);
  font-weight: 300;
  color: var(--color-golden);
  margin-bottom: 20px;
  align-self: flex-start;
}

.app_howtouse_text {
  color: black;
  font-size: max(1vw, 2vh);
  text-align: left;
  font-family: var(--font-base);
  margin: 0;
  align-self: flex-start;
}

/* @media screen and (min-width: 1536px) {
}

@media screen and (max-width: 1536px) {
}

@media screen and (max-width: 1358px) {
  .app_howtouse {
    padding: 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .app_howtouse {
    padding: 2.5rem;
  }
}

@media screen and (max-width: 960px) {
  .app_howtouse_title {
    font-size: 40px;
  }

  .app_howtouse_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 820px) {
  .app_howtouse {
    padding: 2rem;
  }

  .app_howtouse_text {
    font-size: 13px;
  }
} */

@media screen and (max-width: 768px) {
  .app_howtouse {
    height: unset;
    padding: 3rem 2rem;
    scroll-margin-top: 200px;
  }

  .app_howtouse-overlay img {
    width: 100%;
    height: 100%;
  }

  .app_howtouse-content-container {
    flex-direction: column;
  }

  .app_howtouse_title {
    font-size: 32px;
    font-weight: 400;
  }

  .app_howtouse_text {
    font-size: 16px;
    margin: 0px;
  }
}
